import styled from 'styled-components';
import { colors } from '../variables';
import { TypoH2, TypoH3, TypoH6 } from '../Typography';

export const Container = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
`;

export const Page404ContentContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 6.2rem;
`;

export const CustomTypoH2 = styled(TypoH2)`
  color: ${colors.main500};
  padding-top: 4.9rem;
`;

export const P404TypoH3 = styled(TypoH3)`
  color: ${colors.main900};
  padding-top: 3.2rem;
`;

export const P404TypoH6 = styled(TypoH6)`
  color: ${colors.main500};
  padding-top: 2.4rem;
`;

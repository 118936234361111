import React, { useEffect, useState } from 'react';
import { CloseIcon } from '../../variables';
import Modal from '../Modal';
import Button from '../../Button/Button';
import {
  Container,
  ButtonsContainer,
  SavingSpinner,
  TitleContainer,
} from './SaveWarningModal.style';

export interface SaveWarningModalProps {
  onContinueHandler?: () => void;
  onSaveHandler?: () => void;
  onCloseHandler?: () => void;
  modalTitle?: string;
  modalDescription?: string;
  saveBtnTitle?: string;
  continueBtnTitle?: string;
  customSaveBtn?: React.ReactNode;
  customContinueBtn?: React.ReactNode;
  isSaving?: boolean;
  show: boolean;
}

const SaveWarningModal: React.FC<SaveWarningModalProps> = ({
  onContinueHandler,
  onSaveHandler,
  onCloseHandler,
  modalTitle = 'Save changes?',
  modalDescription = 'Save changes before leaving this page.',
  saveBtnTitle = 'Save and Continue',
  continueBtnTitle = 'Continue without saving',
  customContinueBtn,
  customSaveBtn,
  isSaving,
  show,
}) => {
  const [showSaveModal, setShowSaveModal] = useState(false);

  useEffect(() => {
    setShowSaveModal(show);
  }, [show]);

  const closeHandler = () => {
    setShowSaveModal(false);
    if (onCloseHandler) {
      onCloseHandler();
    }
  };

  return (
    <Modal show={showSaveModal}>
      <Container>
        <TitleContainer>
          <p>{modalTitle}</p>
          <CloseIcon onClick={closeHandler} cursor="pointer" />
        </TitleContainer>
        <p>{modalDescription}</p>
        <ButtonsContainer>
          {customContinueBtn || (
            <Button
              btnType="textPrimary"
              onClick={onContinueHandler}
              disabled={isSaving}
            >
              {continueBtnTitle}
            </Button>
          )}
          {customSaveBtn || (
            <Button onClick={onSaveHandler} disabled={isSaving}>
              {saveBtnTitle}
            </Button>
          )}
        </ButtonsContainer>
        {isSaving && <SavingSpinner />}
      </Container>
    </Modal>
  );
};

export default SaveWarningModal;

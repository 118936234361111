import styled from 'styled-components';
import { TypoH3, TypoH5 } from '../Typography/Typography';
import colors from '../variables/colors';

const ERROR_BOUNDARY_IMG_URL =
  'https://abc-public.s3.ca-central-1.amazonaws.com/img/common/errors/500.png';

export const ErrorBoundaryHeading = styled.h1`
  color: ${colors.white};
  font-weight: 700;
  font-size: 150px;
  line-height: 150px;
  margin-bottom: 60px;
  margin-top: 40px;
  font-family: Roboto;
`;

export const ErrorBoundarySubheading = styled(TypoH3)`
  color: ${colors.main100};
  margin-bottom: 28px;
`;

export const ErrorBoundaryText = styled(TypoH5)`
  color: ${colors.white};
  margin-bottom: 20px;
`;

export const ErrorBoundaryContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${ERROR_BOUNDARY_IMG_URL});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${colors.main900};
  padding-left: 40px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
`;

export const ErrorBoundaryHomepageLink = styled.a`
  color: ${colors.white};
  text-decoration-line: underline;
`;

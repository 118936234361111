import React from 'react';
import styled from 'styled-components';
import { IoSettingsOutline, IoApps, sizes } from '../../variables';
import headerIconBaseStyle from '../pageHeaderCommonStyle';

const Container = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: ${sizes.header_items_gap};
`;

interface PHeaderOptionsRProps {
  settingPath: string;
  menuIcon?: React.ReactNode;
}

const PHeaderOptionsR: React.FC<PHeaderOptionsRProps> = ({
  settingPath,
  menuIcon = <div />,
}) => {
  const onSetting = () => {
    window.open(settingPath, '_blank');
  };

  const onApp = () => {
    console.log('onApp clicked');
  };
  return (
    <Container>
      <IoSettingsOutline style={headerIconBaseStyle} onClick={onSetting} />
      <IoApps style={headerIconBaseStyle} onClick={onApp} />
      {menuIcon}
    </Container>
  );
};

export default PHeaderOptionsR;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { AsteriskIcon } from '../variables';
import {
  TextArea,
  LabelArea,
  HintArea,
  TextInputContainer,
  ErrorContainer,
  InfoIcon,
  AsteriskBox,
} from './FormInput.style';
import { TypoOverline1 } from '../Typography';
import { FormTextAreaProps } from './FormInputTypes';

const StyledTypoOverline1 = styled(TypoOverline1)`
  position: absolute;
  right: 0;
`;

const FormTextArea: React.FC<FormTextAreaProps> = ({
  label,
  hint,
  error = false,
  onTextChange,
  highlightOnFocus,
  ...props
}) => {
  const { disabled, required, maxLength } = props;
  const [curValue, setCurValue] = useState<string>('');

  useEffect(() => {
    if (onTextChange) {
      onTextChange(curValue as string);
    }
  }, [curValue]);

  const showTextLength = () => {
    return (
      maxLength && (
        <StyledTypoOverline1>
          {' '}
          {curValue.length}/{maxLength}{' '}
        </StyledTypoOverline1>
      )
    );
  };

  const handleFocus = (e: React.FormEvent<HTMLTextAreaElement>) =>
    highlightOnFocus ? e.currentTarget.select() : null;

  const showErrorIcon = () => {
    return error && hint ? (
      <ErrorContainer>
        <InfoIcon />
      </ErrorContainer>
    ) : null;
  };

  return (
    <TextInputContainer error={error} disabled={disabled}>
      <LabelArea error={error} disabled={disabled}>
        {label ? (
          <>
            {label}{' '}
            {required && (
              <AsteriskBox error={error} disabled={disabled}>
                <AsteriskIcon />
              </AsteriskBox>
            )}
          </>
        ) : null}
      </LabelArea>
      {
        <TextArea
          error={error}
          onFocus={handleFocus}
          onChange={(e) => setCurValue(e.target.value)}
          {...props}
        ></TextArea>
      }
      <HintArea error={error}>
        {showErrorIcon()} {hint}
        {showTextLength()}
      </HintArea>
    </TextInputContainer>
  );
};

export default FormTextArea;

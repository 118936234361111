import React, { ReactNode } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import {
  AddIcon,
  ChevronForwardIcon,
  PlusCircleOutlineIcon,
  IoEllipsisVertical,
  IoPlayOutline,
  IoSendOutline,
  colors,
} from '../variables';
import { ButtonT, ButtonProps, ButtonIconT } from './ButtonTypes';

const MenuIcon = styled(IoEllipsisVertical)`
  color: ${colors.main500};
`;

const isDark = (type?: string): boolean => {
  const newType = type || '';
  const darkTypes = [
    'primary',
    'dark',
    'primaryCritical',
    'primaryWarning',
    'primarySuccess',
    'primaryInfo',
  ];
  return darkTypes.includes(newType);
};

const textTypes = [
  'textPrimary',
  'textDark',
  'textCritical',
  'textWarning',
  'textInfo',
];
const getTextDisplayBgColor = (type?: string): string => {
  const newType = type || '';
  if (textTypes.includes(newType)) {
    return 'unset';
  }
  return colors.main100;
};

const isText = (type?: string): boolean => {
  const newType = type || '';
  return textTypes.includes(newType);
};

export const BtnTypeStyle = ({
  btnType = 'primary',
}: {
  btnType?: ButtonT;
  disabled?: boolean;
}): FlattenSimpleInterpolation => {
  switch (btnType) {
    case 'primary':
      return css`
        background: ${colors.primary700};
        color: ${colors.white};
        border: 1px solid ${colors.primary700};
        &:hover {
          background: ${colors.primary700};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          color: ${colors.white};
          border: 1px solid ${colors.primary700};
          opacity: 0.7;
        }
        &:active {
          background: ${colors.primary700};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          color: ${colors.white};
          border: 1px solid ${colors.primary700};
          opacity: 1;
        }
        &:focus-visible {
          background: ${colors.primary};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          color: ${colors.white};
          border: 1px solid rgba(15, 158, 215, 0.1);
          opacity: 0.7;
          outline: 2px solid rgba(15, 158, 215, 0.1);
        }
      `;
    case 'secondary':
      return css`
        background: ${colors.white};
        color: ${colors.primary700};
        border: 1px solid ${colors.primary700};
        &:hover {
          background: ${colors.primary100};
          color: rgba(15, 158, 215, 0.7);
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          border: 1px solid ${colors.primary};
        }
        &:focus-visible {
          background: ${colors.white};
          color: ${colors.primary};
          border: 1px solid ${colors.primary};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          outline: 2px solid rgba(15, 158, 215, 0.1);
        }
        &:active {
          background: ${colors.primary100};
          color: ${colors.primary700};
          border: 1px solid ${colors.primary700};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
        }
      `;
    case 'textPrimary':
      return css`
        background-color: transparent;
        color: ${colors.primary700};
        border: none;
        &:hover {
          background: ${colors.primary100};
          color: rgba(15, 158, 215, 0.7);
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.15);
        }
        &:focus-visible {
          background: ${colors.white};
          color: ${colors.primary700};
          outline: 2px solid rgba(15, 158, 215, 0.1);
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.15);
        }
        &:active {
          background: ${colors.primary100};
          color: ${colors.primary700};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.15);
          outline: none;
        }
      `;
    case 'primaryLight':
      return css`
        background: ${colors.primaryLightBlue};
        color: ${colors.white};
        border: 1px solid ${colors.primaryLightBlue};
        &:enabled:hover,
        &:active {
          background: ${colors.white};
          color: ${colors.primaryLightBlue};
          border: 1px solid ${colors.primaryLightBlue};
        }
      `;
    case 'tertiary':
      return css`
        background: transparent;
        color: ${colors.primary700};
        &:hover {
          background-color: ${colors.primary100};
          color: ${colors.primary700};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.15);
        }
        &:focus-visible {
          background-color: ${colors.white};
          color: ${colors.primary};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.15);
          outline: 2px solid rgba(15, 158, 215, 0.1);
        }
        &:active {
          background-color: ${colors.primary100};
          color: ${colors.primary700};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.15);
        }
      `;
    case 'tertiaryDark':
      return css`
        background: transparent;
        color: ${colors.main500};
        border: none;
        &:hover {
          background: ${colors.main100};
          color: ${colors.main700};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.15);
        }
        &:focus-visible {
          background: ${colors.white};
          color: ${colors.main700};
          outline: 2px solid rgba(19, 66, 119, 0.1);
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.15);
        }
        &:active {
          background: ${colors.main200};
          color: ${colors.main700};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.15);
          outline: none;
        }
      `;
    case 'dark':
      return css`
        background: ${colors.main};
        color: ${colors.white};
        border: 1px solid ${colors.main};
        &:hover {
          background: ${colors.main};
          opacity: 0.7;
          color: ${colors.white};
          border: 1px solid ${colors.main};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.15);
        }
        &:focus-visible {
          background: ${colors.main600};
          color: ${colors.white};
          outline: 2px solid rgba(19, 66, 119, 0.1);
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.15);
        }
        &:active {
          background: ${colors.main};
          color: ${colors.white};
          border: 1px solid ${colors.main};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.15);
          outline: none;
        }
      `;
    case 'secondaryDark':
      return css`
        background: ${colors.white};
        color: ${colors.main};
        border: 1px solid ${colors.main};
        &:hover {
          background: ${colors.main100};
          color: ${colors.main600};
          border: 1px solid ${colors.main600};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.15);
        }
        &:focus-visible {
          background: ${colors.white};
          color: ${colors.main600};
          border: 1px solid ${colors.main600};
          outline: 2px solid rgba(19, 66, 119, 0.1);
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.15);
        }
        &:active {
          background: ${colors.main200};
          color: ${colors.main700};
          border: 1px solid ${colors.main700};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.15);
          outline: none;
        }
      `;
    case 'textDark':
      return css`
        background: ${colors.white};
        color: ${colors.main600};
        border: none;
        &:hover {
          background: ${colors.main100};
          color: ${colors.main600};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.15);
        }
        &:focus-visible {
          background: ${colors.white};
          color: ${colors.main};
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
          outline: 2px solid rgba(19, 66, 119, 0.1);
        }
        &:active {
          background: ${colors.main200};
          color: ${colors.main700};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.15);
          outline: none;
        }
      `;
    case 'danger':
      return css`
        background: ${colors.white};
        color: ${colors.secondaryRed};
        border: 1px solid ${colors.secondaryRed};
        &:enabled:hover,
        &:active {
          background: ${colors.secondaryRed};
          color: ${colors.white};
          border: 1px solid ${colors.secondaryRed};
        }
      `;
    case 'primaryCritical':
      return css`
        background: ${colors.primaryRed};
        color: ${colors.white};
        border: 1px solid ${colors.primaryRed};
        &:hover {
          background: rgba(218, 20, 21, 0.7);
        }
        &:focus-visible {
          background: ${colors.critical};
          outline: 2px solid rgba(255, 23, 50, 0.1);
        }
        &:active {
          background: ${colors.primaryRed};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          outline: none;
        }
      `;
    case 'secondaryCritical':
      return css`
        background: ${colors.white};
        color: ${colors.primaryRed};
        border: 1px solid ${colors.primaryRed};
        &:hover {
          background: ${colors.critical200};
          color: rgba(218, 20, 21, 0.7);
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
        }
        &:focus-visible {
          background: ${colors.white};
          color: ${colors.critical};
          border: 1px solid ${colors.critical};
          outline: 2px solid rgba(255, 23, 50, 0.1);
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
        }
        &:active {
          background: ${colors.critical200};
          color: ${colors.primaryRed};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          outline: none;
        }
      `;
    case 'textCritical':
      return css`
        background: ${colors.white};
        color: ${colors.primaryRed};
        border: none;
        &:hover {
          background: ${colors.critical200};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          color: rgba(218, 20, 21, 0.7);
        }
        &:focus-visible {
          background: ${colors.white};
          color: ${colors.critical};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          outline: 2px solid rgba(255, 23, 50, 0.1);
        }
        &:active {
          background: ${colors.critical200};
          color: ${colors.primaryRed};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          outline: none;
        }
      `;
    case 'primaryWarning':
      return css`
        background: ${colors.primaryYellow};
        color: ${colors.main900};
        border: 1px solid ${colors.primaryYellow};
        &:hover {
          background: rgba(255, 190, 23, 0.7);
          color: rgba(6, 31, 58, 0.7);
          border: 1px solid rgba(255, 190, 23, 0.7);
        }
        &:focus-visible {
          background: ${colors.primaryYellow};
          outline: 2px solid rgba(255, 23, 50, 0.1);
          border: 1px solid ${colors.primaryYellow};
        }
        &:active {
          background: ${colors.primaryYellow};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          border: 1px solid ${colors.primaryYellow};
          outline: none;
        }
      `;
    case 'secondaryWarning':
      return css`
        background: ${colors.white};
        color: ${colors.main900};
        border: 1px solid ${colors.warning700};
        &:hover {
          background: ${colors.warning200};
          color: rgba(6, 31, 58, 0.7);
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
        }
        &:focus-visible {
          background: ${colors.white};
          color: ${colors.main900};
          border: 1px solid ${colors.warning};
          outline: 2px solid rgba(255, 190, 50, 0.1);
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
        }
        &:active {
          background: ${colors.warning200};
          color: ${colors.main900};
          border: 1px solid ${colors.warning700};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          outline: none;
        }
      `;
    case 'textWarning':
      return css`
        background: ${colors.white};
        color: ${colors.main900};
        border: none;
        &:hover {
          background: ${colors.main100};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          color: rgba(6, 31, 5, 0.7);
        }
        &:focus-visible {
          background: ${colors.white};
          color: ${colors.main900};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          outline: 2px solid rgba(6, 31, 5, 0.1);
        }
        &:active {
          background: ${colors.main100};
          color: ${colors.main900};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          outline: none;
        }
      `;
    case 'primarySuccess':
      return css`
        background: ${colors.primaryGreen};
        color: ${colors.white};
        border: 1px solid ${colors.primaryGreen};
        &:hover {
          background: rgba(40, 125, 60, 0.7);
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
        }
        &:focus-visible {
          background: ${colors.primaryGreen};
          outline: 2px solid rgba(40, 125, 60, 0.1);
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
        }
        &:active {
          background: ${colors.primaryGreen};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          outline: none;
        }
      `;
    case 'secondarySuccess':
      return css`
        background: ${colors.white};
        color: ${colors.primaryGreen};
        border: 1px solid ${colors.primaryGreen};
        &:hover {
          background: ${colors.success200};
          color: rgba(40, 125, 60, 0.7);
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
        }
        &:focus-visible {
          background: ${colors.white};
          color: ${colors.primaryGreen};
          outline: 2px solid rgba(40, 125, 60, 0.1);
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
        }
        &:active {
          background: ${colors.success200};
          color: ${colors.primaryGreen};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          outline: none;
        }
      `;
    case 'textSuccess':
      return css`
        background: ${colors.white};
        color: ${colors.primaryGreen};
        border: none;
        &:hover {
          background: ${colors.success200};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          color: rgba(6, 31, 5, 0.7);
        }
        &:focus-visible {
          background: ${colors.white};
          color: ${colors.primaryGreen};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          outline: 2px solid rgba(6, 31, 5, 0.1);
        }
        &:active {
          background: ${colors.success200};
          color: ${colors.primaryGreen};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          outline: none;
        }
      `;
    case 'primaryInfo':
      return css`
        background: ${colors.info700};
        color: ${colors.white};
        border: 1px solid ${colors.info700};
        &:hover {
          background: rgba(46, 90, 172, 0.7);
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          outline: none;
        }
        &:focus-visible {
          background: ${colors.info};
          outline: 2px solid rgba(46, 119, 255, 0.1);
        }
        &:active {
          background: ${colors.info700};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          outline: none;
        }
      `;
    case 'secondaryInfo':
      return css`
        background: ${colors.white};
        color: ${colors.info700};
        border: 1px solid ${colors.info700};
        &:hover {
          background: ${colors.info200};
          color: rgba(46, 90, 172, 0.7);
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
        }
        &:focus-visible {
          background: ${colors.white};
          color: ${colors.info};
          outline: 2px solid rgba(46, 119, 255, 0.1);
          border: 1px solid ${colors.info};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
        }
        &:active {
          background: ${colors.info200};
          color: ${colors.info700};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          outline: none;
        }
      `;
    case 'textInfo':
      return css`
        background: ${colors.white};
        color: ${colors.info700};
        border: none;
        &:hover {
          background: ${colors.info200};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          color: rgba(46, 90, 172, 0.7);
        }
        &:focus-visible {
          background: ${colors.white};
          color: ${colors.info};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          outline: 2px solid rgba(46, 119, 255, 0.1);
        }
        &:active {
          background: ${colors.info200};
          color: ${colors.info700};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          outline: none;
        }
      `;
    case 'link':
      return css`
        background: ${colors.white};
        color: ${colors.primary700};
        border: none;
        &:hover {
          color: rgba(15, 158, 215, 0.7);
        }
        &:focus-visible {
          color: ${colors.primary700};
          outline: 2px solid rgba(15, 158, 215, 0.1);
        }
        &:active {
          color: ${colors.primary700};
          outline: none;
        }
      `;
    case 'whiteIcon':
      return css`
        color: ${colors.main100};
        &:hover {
          background: ${colors.main100};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          color: ${colors.white};
          opacity: 0.1;
        }
        &:focus-visible {
          background: ${colors.white};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          color: ${colors.white};
          opacity: 0.2;
          outline: 2px solid rgba(249, 251, 253, 0.1);
        }
        &:active {
          background: ${colors.main200};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          color: ${colors.white};
          border: 1px solid ${colors.primary700};
          opacity: 0.2;
        }
      `;
    default:
      // simple
      return css``;
  }
};

export const BaseStyle = (): FlattenSimpleInterpolation => css`
  display: flex;
  min-width: max-content;
  flex-wrap: nowrap;
  gap: 4px;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: none;
  padding: 5px 16px;
  &,
  &:hover,
  &:active,
  &:focus:focus-visible {
    outline: none;
  }
`;

const IconBaseStyle = (): FlattenSimpleInterpolation =>
  css`
    border-radius: 4px;
    padding: 3.2px;
    height: 32px;
    width: 32px;
    cursor: pointer;
  `;

const DisabledStyle = ({
  btnType,
}: {
  btnType?: ButtonT;
}): FlattenSimpleInterpolation =>
  css`
    &:disabled {
      color: ${colors.main400};
      background-color: ${isDark(btnType)
        ? colors.main200
        : getTextDisplayBgColor(btnType)};
      border-color: ${isText(btnType) ? 'transparent' : colors.main200};
      outline: none;
      box-shadow: none;
      opacity: 1;
      cursor: auto;
    }
  `;

const iconStyles: React.CSSProperties = {
  width: '24px',
  height: '24px',
  display: 'block',
};

export const renderIcons = (iconType?: ButtonIconT): ReactNode => {
  switch (iconType) {
    case 'plus':
      return <AddIcon style={iconStyles} />;
    case 'greater':
      return <ChevronForwardIcon style={iconStyles} />;
    case 'play':
      return <IoPlayOutline style={iconStyles} />;
    case 'send':
      return <IoSendOutline style={iconStyles} />;
    case 'delete':
      return <PlusCircleOutlineIcon style={iconStyles} />;
    case 'verticalEllipsis':
      return <MenuIcon style={iconStyles} />;
    default:
      return '';
  }
};

export const StyledButtonIcon = styled.button<ButtonProps>`
  ${IconBaseStyle}
  ${BtnTypeStyle}
  ${DisabledStyle}
`;

export const StyledButton = styled.button<ButtonProps>`
  ${BaseStyle}
  ${BtnTypeStyle}
  ${DisabledStyle}
`;

import React from 'react';
import ENG from '../translation/translationEn.json';
import FR from '../translation/translationFr.json';
import { VetGenusLogoDark } from '../media';
import { TypoH6 } from '../Typography';
import {
  CloudyNightOutlineIcon,
  ContentContainer,
  MaintenanceLayout,
  Header,
  IconContainer,
  Subheading,
  TextContainer,
  TitleHeadingWrapper,
} from './MaintenancePage.style';

interface MaintenancePageProps {
  lang?: 'en' | 'fr';
}

const MaintenancePage: React.FC<MaintenancePageProps> = ({ lang = 'en' }) => {
  const translation = lang === 'fr' ? FR : ENG;

  return (
    <MaintenanceLayout>
      <Header>{<VetGenusLogoDark height="5rem" width="20rem" />}</Header>
      <ContentContainer>
        <IconContainer>
          <CloudyNightOutlineIcon />
        </IconContainer>
        <TextContainer>
          <TitleHeadingWrapper>
            <h1>{translation.maintenancePage.titleHeading}</h1>
          </TitleHeadingWrapper>
          <Subheading>{translation.maintenancePage.subheading}</Subheading>
          <p>
            <TypoH6>{translation.maintenancePage.paragraph}</TypoH6>
          </p>
        </TextContainer>
      </ContentContainer>
    </MaintenanceLayout>
  );
};

export default MaintenancePage;

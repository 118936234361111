import React from 'react';
import { GroupTypeBase, components, IndicatorProps } from 'react-select';
import { CaretDownOutline } from '../../../variables/icons';
import { DropdownOptionType } from '../../DropdownTypes';

const DropdownIndicator:
  | React.FC<
      IndicatorProps<
        DropdownOptionType,
        boolean,
        GroupTypeBase<DropdownOptionType>
      >
    >
  | undefined = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownOutline />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;

import React, { useState } from 'react';
import { AsteriskIcon } from '../variables';
import {
  TextInput,
  NewArea,
  HintText,
  TextContainer,
  LabelArea,
  HintArea,
  ToolTip,
  TextInputContainer,
  ErrorContainer,
  IconContainer,
  DefaultContainer,
  AsteriskBox,
  InfoIcon,
  ListIcon,
  CancelIcon,
  EyeIcon,
  EyeSlashIcon,
} from './FormInput.style';
import { FormInputProps } from './FormInputTypes';

const FormInput: React.FC<FormInputProps> = ({
  inputType,
  label,
  icon,
  hint,
  removable,
  highlightOnFocus,
  onRemove,
  error = false,
  ...props
}) => {
  const { disabled, required } = props;
  const [hasPasswordShown, setHasPasswordShown] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const showErrorIcon = () => {
    return hint && icon !== 'tooltip' ? (
      <>
        {error && (
          <ErrorContainer>
            <InfoIcon size={16} />
          </ErrorContainer>
        )}
        <HintText hasError={error}>{hint}</HintText>
      </>
    ) : null;
  };

  const showToolTip = () => {
    return showTooltip && hint && <ToolTip> {hint}</ToolTip>;
  };

  const handleFocus = (e: React.FormEvent<HTMLInputElement>) =>
    highlightOnFocus ? e.currentTarget.select() : null;

  const selectedEye = (
    <IconContainer
      onClick={() => setHasPasswordShown(!hasPasswordShown)}
      inputDisabled={disabled}
      error={error}
    >
      {' '}
      {hasPasswordShown ? <EyeIcon /> : <EyeSlashIcon />}
    </IconContainer>
  );

  const selectedIcon = () => {
    if (inputType === 'password') {
      return selectedEye;
    }
    switch (icon) {
      case 'list':
        return (
          <IconContainer disabled={disabled} error={error}>
            <ListIcon />
          </IconContainer>
        );
      case 'tooltip':
        return (
          <DefaultContainer>
            <InfoIcon
              size={24}
              onMouseOver={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            />
          </DefaultContainer>
        );
      default:
        return null;
    }
  };

  const selectedType = () => {
    switch (inputType) {
      case 'password':
        return hasPasswordShown ? 'text' : 'password';
      default:
        return 'text';
    }
  };

  const hasIcon = () => {
    if (icon) {
      return true;
    }
    return inputType === 'password' || removable;
  };

  const removableIcon = removable ? (
    <IconContainer disabled={disabled} error={error} onClick={onRemove}>
      <CancelIcon />
    </IconContainer>
  ) : null;

  return (
    <TextInputContainer error={error} disabled={disabled}>
      <LabelArea error={error} disabled={disabled}>
        {label ? (
          <>
            {label}{' '}
            {required && (
              <AsteriskBox error={error} disabled={disabled}>
                <AsteriskIcon />
              </AsteriskBox>
            )}
          </>
        ) : null}
      </LabelArea>
      {hasIcon() ? (
        <TextContainer error={error} required={required} disabled={disabled}>
          <NewArea
            type={selectedType()}
            disabled={disabled}
            onFocus={handleFocus}
            required={required}
            error={error}
            {...props}
          />
          {removableIcon}
          {selectedIcon()}
        </TextContainer>
      ) : (
        <TextInput
          type={selectedType()}
          error={error}
          onFocus={handleFocus}
          {...props}
        ></TextInput>
      )}
      <HintArea error={error}>
        {showErrorIcon()}
        {showToolTip()}
      </HintArea>
    </TextInputContainer>
  );
};

export default FormInput;

import React from 'react';
import ENG from '../translation/translationEn.json';
import FR from '../translation/translationFr.json';
import {
  P404TypoH3,
  P404TypoH6,
  Page404ContentContainer,
  CustomTypoH2,
  Container,
} from './Page404.style';

export interface Page404Props {
  lang?: 'en' | 'fr';
  header?: React.ReactNode;
}

const Page404: React.FC<Page404Props> = ({ header, lang = 'en' }) => {
  const translation = lang === 'fr' ? FR : ENG;

  return (
    <Container>
      {header}
      <Page404ContentContainer>
        <img
          src="https://abc-public.s3.ca-central-1.amazonaws.com/img/common/errors/404.png"
          width="490"
          height="200"
          alt={'404'}
        />
        <CustomTypoH2> {translation.page404.pageNotFound}</CustomTypoH2>
        <P404TypoH3>{translation.page404.permission}</P404TypoH3>
        <P404TypoH6>{translation.page404.apology}</P404TypoH6>
      </Page404ContentContainer>
    </Container>
  );
};

export default Page404;

import styled from 'styled-components';
import { colors, DeleteIcon as DefaultDeleteIcon } from '../variables';

const DeleteIcon = styled(DefaultDeleteIcon)`
  cursor: pointer;
  &:hover {
    color: ${colors.secondaryRed};
  }
`;

export default DeleteIcon;

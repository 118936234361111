import React from 'react';
import styled from 'styled-components';
import { colors, BsInfoCircleFill } from '../../variables';

interface HintAreaProps {
  hasError?: boolean;
  hint?: string;
}

const HintContainer = styled.span<{ hasError?: boolean }>`
  display: flex;
  align-items: flex-start;
  color: ${(p) => (p.hasError ? colors.critical700 : colors.main500)};
  font-size: 1.2rem;
  line-height: 2rem;
  font-family: Roboto;
`;

const HintText = styled.span<{ hasError?: boolean }>`
  padding-top: 4px;
  color: ${(p) => (p.hasError ? colors.critical700 : colors.main500)}; ;
`;

const InfoIcon = styled(BsInfoCircleFill)`
  width: 1.6rem;
  height: 1.6rem;
  padding-right: 4px;
  padding-top: 6px;
  color: ${colors.critical700};
`;

const HintArea: React.FC<HintAreaProps> = ({ hasError, hint }) => {
  return (
    <>
      <HintContainer>
        {hasError && <InfoIcon />}{' '}
        <HintText hasError={hasError}>{hint}</HintText>
      </HintContainer>
    </>
  );
};

export default HintArea;

import styled from 'styled-components';
import {
  colors,
  CloudyNightOutlineIcon as CloudyNightOutlineIconBase,
} from '../variables';
import { TypoH2 } from '../Typography';

const MAINTENANCE_HEADER_HEIGHT = '4.8rem';
const VETGENUS_ICON_GRAD_PNG_URL =
  'https://abc-public.s3.ca-central-1.amazonaws.com/img/common/logos/vetgenus-icon-grad.png';

export const MaintenanceLayout = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0.1;
    content: '';
    background-image: url(${VETGENUS_ICON_GRAD_PNG_URL});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right -12.5rem top -10.5rem;
  }
`;

export const Header = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: ${MAINTENANCE_HEADER_HEIGHT};
  padding: 4rem 5rem;
`;

export const ContentContainer = styled.div`
  position: absolute;
  display: grid;
  grid-template-rows: 1fr 1fr;
  top: ${MAINTENANCE_HEADER_HEIGHT};
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  justify-content: center;
  padding: 4rem;
  gap: 6rem;
  text-align: center;
  object-fit: cover;
`;

export const IconContainer = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
`;

export const CloudyNightOutlineIcon = styled(CloudyNightOutlineIconBase)`
  color: ${colors.main};
  width: 100%;
  height: 100%;
`;

export const TextContainer = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  gap: 3.2rem;
`;

export const TitleHeadingWrapper = styled(TypoH2)`
  color: ${colors.main500};
`;

export const Subheading = styled.h2`
  font-family: 'Roboto';
  font-size: 40px;
  font-weight: 500;
  line-height: 40px;
`;

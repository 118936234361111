import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import {
  colors,
  SubtractIcon,
  CloseIcon as DefaultCloseIcon,
} from '../variables';
import '@fontsource/roboto';

export type TabTypes = 'selected' | 'unselected';

export interface ContainerProps {
  state: TabTypes;
}

export const IconWrapper = styled.div`
  margin-right: 0.4rem;
  position: relative;
`;

export const RemoveIcon = styled(SubtractIcon)`
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  margin-left: 0.3rem;
  margin-top: 0.3rem;
`;

export const CloseIcon = styled(DefaultCloseIcon)`
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  margin-left: 0.3rem;
  margin-top: 0.3rem;
`;

export interface DraftTabProps {
  state: TabTypes;
  firstName: string;
  lastName: string;
}

export const IconTypeStyle = ({
  state,
}: {
  state: TabTypes;
}): FlattenSimpleInterpolation => {
  switch (state) {
    case 'selected':
      return css`
        background: ${colors.primary700};
        color: ${colors.white};
        z-index: 2;
        &:hover {
          background: ${colors.primary700};
          opacity: 0.1;
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          color: rgba(255, 255, 255, 1);
          border-radius: 4px;
        }
        &:focus {
          background: ${colors.white};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          color: ${colors.white};
          border-radius: 4px;
          opacity: 0.2;
        }
        &:active {
          background: ${colors.main200};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
          color: ${colors.white};
          opacity: 0.1;
        }
      `;
    case 'unselected':
      return css`
        margin-right: 0.4rem;
        color: ${colors.main900};
        z-index: 2;
        &:hover {
          background: ${colors.main100};
          color: ${colors.main900};
          border-radius: 4px;
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
        }
        &:focus-within {
          background: ${colors.white};
          color: ${colors.main900};
          border-radius: 4px;
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
        }
        &:active {
          background: ${colors.main200};
          color: ${colors.main900};
          box-shadow: 0px 2px 8px 0.08px rgba(6, 31, 58, 0.05);
        }
      `;
    default:
      return css``;
  }
};

export const DraftTabTypeStyle = ({
  state,
}: {
  state: TabTypes;
}): FlattenSimpleInterpolation => {
  switch (state) {
    case 'selected':
      return css`
        background: ${colors.primary700};
        color: ${colors.white};
      `;
    case 'unselected':
      return css`
        background: ${colors.white};
        color: ${colors.main900};
      `;
    default:
      return css``;
  }
};

export const BaseStyle = (): FlattenSimpleInterpolation => css`
  display: flex;
  flex-direction: row;
  border-radius: 2px 2px 0px 0px;
  align-items: center;
  font-weight: 500;
  font-family: 'Roboto';
  font-style: normal;
  align-items: center;
  font-size: 1.4rem;
  box-shadow: 0px 6px 24px 0.1px rgba(6, 31, 58, 0.15);
  width: 24.4rem;
  height: 4rem;
  &,
  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
`;

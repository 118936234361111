import styled from 'styled-components';
import { devices } from '../../../variables';
import { DeleteIcon } from '../../../DeleteIcon';

export const Container = styled.div`
  display: grid;
  align-items: center;
  color: inherit;
  grid-template-columns: max-content 1fr;
`;

export const KeyPairDeleteIcon = styled(DeleteIcon)`
  margin-right: 0.5rem;
  @media ${devices.phone} {
    margin-right: 0.3rem;
  }
`;

export const ChildrenContainer = styled.div``;

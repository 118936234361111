/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { GroupTypeBase, Styles } from 'react-select';
import { colors } from '../../variables';
import dropdownStyles from '../Dropdown/Dropdown.style';
import { DropdownOptionType } from '../DropdownTypes';

const HOVER_BG_COLOR = '#1E354D';
const FOCUSED_BG_COLOR = '#607082';
const FOCUSED_BORDER_COLOR = '#1E354D';

export const getBorderColor = (
  hasError: boolean,
  menuIsOpen: boolean | undefined,
  hideBorder: boolean
): string => {
  if (hasError) {
    return colors.critical700;
  }
  if (hideBorder) {
    return 'transparent';
  }
  return menuIsOpen ? FOCUSED_BORDER_COLOR : colors.main400;
};

export const getControlBGColor = (
  isFocused: boolean
  // isDisabled: boolean | undefined
): string => {
  // if (isDisabled) {
  //   return colors.main200;
  // }
  return isFocused ? FOCUSED_BG_COLOR : colors.main900;
};

const darkDropdownStyles:
  | Partial<
      Styles<DropdownOptionType, boolean, GroupTypeBase<DropdownOptionType>>
    >
  | undefined = {
  ...dropdownStyles,
  control: (provided, state) => {
    const { isFocused, menuIsOpen, isDisabled } = state;
    const { hasError, hideBorder } = state.selectProps;
    const controlBaseStyles =
      dropdownStyles && dropdownStyles.control
        ? dropdownStyles.control(provided, state)
        : provided;

    return {
      ...controlBaseStyles,
      // backgroundColor: getControlBGColor(isFocused, isDisabled),
      backgroundColor: getControlBGColor(isFocused),
      borderColor: getBorderColor(hasError, menuIsOpen, hideBorder),
      color: isDisabled ? colors.main400 : colors.white,
      '&:hover': {
        backgroundColor: menuIsOpen ? FOCUSED_BG_COLOR : HOVER_BG_COLOR,
      },
      '&:focus-within': {
        borderColor: hasError ? colors.critical700 : FOCUSED_BORDER_COLOR,
      },
    };
  },
  input: (provided, state) => ({
    ...provided,
    caretColor: colors.white,
    color: state.isDisabled ? colors.main400 : colors.white,
  }),
  dropdownIndicator: (provided, state) => {
    const dropdownIndicatorBaseStyles =
      dropdownStyles && dropdownStyles.dropdownIndicator
        ? dropdownStyles.dropdownIndicator(provided, state)
        : provided;
    return {
      ...dropdownIndicatorBaseStyles,
      color: state.isDisabled ? colors.main400 : colors.white,
    };
  },
  placeholder: (provided, state) => {
    const placeholderBaseStyles =
      dropdownStyles && dropdownStyles.placeholder
        ? dropdownStyles.placeholder(provided, state)
        : provided;
    return {
      ...placeholderBaseStyles,
      color: state.isDisabled ? colors.main400 : colors.white,
    };
  },
  singleValue: (provided, props) => {
    const singleValueBaseStyles =
      dropdownStyles && dropdownStyles.singleValue
        ? dropdownStyles.singleValue(provided, props)
        : provided;
    return {
      ...singleValueBaseStyles,
      color: props.isDisabled ? colors.main400 : colors.white,
    };
  },
};

export default darkDropdownStyles;

export type CommonENVConfigT = {
  PORTAL_API: string;

  RESEARCH_GENUS_URL: string;
  MARKET_GENUS_URL: string;
  OPS_GENUS_URL: string;
  PET_GENUS_URL: string;
  ROUNDS_URL: string;
  WHITEBOARD_URL: string;
  PORTAL_BASE_URL: string;
  PROFILE_URL: string;
  ADMIN_URL: string;
  CONFIG_URL: string;
  SIGN_IN_URL: string;
  HELP_CENTER_URL: string;

  ABC_LAUNCH_DARKLY_CLIENT_SIDE_ID: string;
};

export type AppStageT = 'local' | 'preview' | 'dev' | 'staging' | 'prod';

const getCommonEnvConfig = (appStage: AppStageT): CommonENVConfigT => {
  switch (appStage) {
    case 'prod':
      return {
        PORTAL_API: 'https://portal-api.vetgenus.com',
        MARKET_GENUS_URL: 'https://market.vetgenus.com',
        RESEARCH_GENUS_URL: 'https://research.vetgenus.com',
        OPS_GENUS_URL: 'https://ops.vetgenus.com',
        PET_GENUS_URL: 'https://pet.vetgenus.com',
        ROUNDS_URL: 'https://rounding.vetgenus.com',
        WHITEBOARD_URL: 'https://rounding.vetgenus.com/whiteboard',
        PORTAL_BASE_URL: 'https://portal.vetgenus.com',
        PROFILE_URL: 'https://portal.vetgenus.com/profile/info',
        ADMIN_URL: 'https://portal.vetgenus.com/admin',
        CONFIG_URL: 'https://portal.vetgenus.com/config',
        SIGN_IN_URL: 'https://portal.vetgenus.com/auth/sign-in',
        HELP_CENTER_URL:
          'https://abcintelligence.atlassian.net/wiki/spaces/VGS/overview',

        ABC_LAUNCH_DARKLY_CLIENT_SIDE_ID: '64629e853f386911ea0cc2d0',
      };
    case 'staging':
      return {
        PORTAL_API: 'https://portal-api-staging.vetgenus.com',
        MARKET_GENUS_URL: 'https://market-staging.vetgenus.com',
        RESEARCH_GENUS_URL: 'https://research-staging.vetgenus.com',
        OPS_GENUS_URL: 'https://ops-staging.vetgenus.com',
        PET_GENUS_URL: 'https://pet-staging.vetgenus.com',
        ROUNDS_URL: 'https://rounding-staging.vetgenus.com',
        WHITEBOARD_URL: 'https://rounding-staging.vetgenus.com/whiteboard',
        PORTAL_BASE_URL: 'https://portal-staging.vetgenus.com',
        PROFILE_URL: 'https://portal-staging.vetgenus.com/profile/info',
        ADMIN_URL: 'https://portal-staging.vetgenus.com/admin',
        CONFIG_URL: 'https://portal-staging.vetgenus.com/config',
        SIGN_IN_URL: 'https://portal-staging.vetgenus.com/auth/sign-in',
        HELP_CENTER_URL:
          'https://abcintelligence.atlassian.net/wiki/spaces/VGS/overview',

        ABC_LAUNCH_DARKLY_CLIENT_SIDE_ID: '646383f6bfd7a312ab29a53f',
      };
    case 'dev':
      return {
        PORTAL_API: 'https://portal-api-dev.vetgenus.com',

        RESEARCH_GENUS_URL: 'https://research-dev.vetgenus.com',
        MARKET_GENUS_URL: 'https://market-dev.vetgenus.com',
        OPS_GENUS_URL: 'https://ops-dev.vetgenus.com',
        PET_GENUS_URL: 'https://pet-dev.vetgenus.com',
        ROUNDS_URL: 'https://rounding-dev.vetgenus.com',
        WHITEBOARD_URL: 'https://rounding-dev.vetgenus.com/whiteboard',
        PORTAL_BASE_URL: 'https://portal-dev.vetgenus.com',
        PROFILE_URL: 'https://portal-dev.vetgenus.com/profile/info',
        ADMIN_URL: 'https://portal-dev.vetgenus.com/admin',
        CONFIG_URL: 'https://portal-dev.vetgenus.com/config',
        SIGN_IN_URL: 'https://portal-dev.vetgenus.com/auth/sign-in',
        HELP_CENTER_URL:
          'https://abcintelligence.atlassian.net/wiki/spaces/VGS/overview',

        ABC_LAUNCH_DARKLY_CLIENT_SIDE_ID: '646383cac43c691394a159ab',
      };

    case 'preview':
      return {
        PORTAL_API: 'https://portal-dev-preview.abc-preview.net',

        RESEARCH_GENUS_URL: `https://research-dev.vetgenus.com`,
        MARKET_GENUS_URL: `https://market-dev.vetgenus.com`,
        OPS_GENUS_URL: 'https://ops-dev.vetgenus.com',
        PET_GENUS_URL: 'https://pet-dev.vetgenus.com',
        ROUNDS_URL: 'https://rounding-dev.vetgenus.com',
        WHITEBOARD_URL: 'https://rounding-dev.vetgenus.com/whiteboard',
        PORTAL_BASE_URL: 'https://dev-preview.d3vn3wte9srinl.amplifyapp.com',
        PROFILE_URL:
          'https://dev-preview.d3vn3wte9srinl.amplifyapp.com/profile/info',
        ADMIN_URL: 'https://dev-preview.d3vn3wte9srinl.amplifyapp.com/admin',
        CONFIG_URL: 'https://dev-preview.d3vn3wte9srinl.amplifyapp.com/config',
        SIGN_IN_URL:
          'https://dev-preview.d3vn3wte9srinl.amplifyapp.com/auth/sign-in',
        HELP_CENTER_URL:
          'https://abcintelligence.atlassian.net/wiki/spaces/VGS/overview',

        ABC_LAUNCH_DARKLY_CLIENT_SIDE_ID: '646383cac43c691394a159ab',
      };
    default:
      // local
      return {
        PORTAL_API: 'http://localhost:8887',

        RESEARCH_GENUS_URL: 'http://localhost:3004',
        MARKET_GENUS_URL: 'http://localhost:3008',
        OPS_GENUS_URL: 'http://localhost:3000',
        PET_GENUS_URL: 'http://localhost:3003',
        ROUNDS_URL: 'http://localhost:3002',
        WHITEBOARD_URL: 'http://localhost:3002/whiteboard',
        PORTAL_BASE_URL: 'http://localhost:3001',
        PROFILE_URL: 'http://localhost:3001/profile/info',
        ADMIN_URL: 'http://localhost:3001',
        CONFIG_URL: 'http://localhost:3001/config',
        SIGN_IN_URL: 'http://localhost:3001/auth/sign-in',
        HELP_CENTER_URL:
          'https://abcintelligence.atlassian.net/wiki/spaces/VGS/overview',

        ABC_LAUNCH_DARKLY_CLIENT_SIDE_ID: '646383cac43c691394a159ab',
      };
  }
};

export default getCommonEnvConfig;

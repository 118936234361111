import React, { Component, ErrorInfo, ReactNode } from 'react';
import { LocaleT } from '../types';
import { TypoH5 } from '../Typography';
import { VetGenusLogoWhite } from '../media';
import ENG from '../translation/translationEn.json';
import FR from '../translation/translationFr.json';
import {
  ErrorBoundaryContainer,
  ErrorBoundaryHeading,
  ErrorBoundaryHomepageLink,
  ErrorBoundarySubheading,
  ErrorBoundaryText,
} from './ErrorBoundary.style';

export interface ErrorBoundaryProps {
  children: ReactNode;
  homepageLink: string;
  lang?: LocaleT;
  catchErrorCallback?: (err: Error) => void;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state: ErrorBoundaryState = {
    hasError: false,
  };

  public static getDerivedStateFromError(): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can also log the error to an error reporting service
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo);
    this.props.catchErrorCallback?.(error);
  }

  public render(): ReactNode {
    const translation = this.props.lang === 'fr' ? FR : ENG;
    if (this.state.hasError) {
      return (
        <ErrorBoundaryContainer>
          <VetGenusLogoWhite height="40px" width="191.4px" />
          <ErrorBoundaryHeading>
            {translation.errorBoundary.titleHeading}
          </ErrorBoundaryHeading>
          <ErrorBoundarySubheading>
            {translation.errorBoundary.subheading}
          </ErrorBoundarySubheading>
          <ErrorBoundaryText>
            {translation.errorBoundary.message}
            <br />
            {translation.errorBoundary.apology}
          </ErrorBoundaryText>
          <TypoH5>
            <ErrorBoundaryHomepageLink href={this.props.homepageLink}>
              {translation.errorBoundary.homepage}
            </ErrorBoundaryHomepageLink>
          </TypoH5>
        </ErrorBoundaryContainer>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
